import React from 'react'
import './first-page.css'
import Lifecoaching from "../assets/Hero/Life Coaching Services.jpg";
import Navbar from '../Common/Navbar/Navbar';
import Footer from '../Common/Footer/Footer';
const First_page = () => {
  return (
    <div>
      <Navbar />
      <>
  <header>
    <h1>Criya Hub</h1>
    <h3>Empowering Individuals, Resolving Conflicts, and Cultivating Growth</h3>
  </header>
  <img src={Lifecoaching} alt="Large Image" />
  <main>
    <section>
      <h3>Welcome to <span className='heading-1'>  Criya Samvad by Criya Hub. </span></h3>
      <h2>Unlocking the Potential of Relationships and Personal Growth</h2>
      <p>
        Individuals seeking to transform their lives, enhance relationships, or
        overcome personal challenges will find comprehensive support and
        guidance from Criya Samvad, a dedicated Master Life Coach. Employing a
        diverse range of proven techniques and therapies, Criya Samvad is
        committed to facilitating lasting positive change.
      </p>
    </section>
    <section>
      <h2>Services Offered:</h2>
      <h3>Couples Counseling:</h3>
      <p>
        Embark on a journey to strengthen relationships through evidence-based
        approaches, including:
      </p>
    </section>
    <section>
      <h3>Emotionally Focused Therapy (EFT):</h3>
      <p>
        Addressing core emotional needs and fostering secure attachments, Criya
        Samvad facilitates a deeper connection between partners.
      </p>
    </section>
    <section>
      <h3>Cognitive Behavioral Therapy (CBT):</h3>
      <p>
        Uncovering and reshaping thought patterns, Criya Samvad fosters
        healthier communication and understanding within relationships.
      </p>
    </section>
    <section>
      <h3>Stress and Anxiety Management::</h3>
      <p>
        Learning effective strategies to navigate life's challenges with
        resilience and composure.
      </p>
    </section>
    <section>
      <h3>Professional Addiction Counselling:</h3>
      <p>
        Reclaiming control over one's life and breaking free from the chains of
        addiction with Criya Samvad's compassionate and expert guidance.
      </p>
    </section>
    <section>
      <h3>Depression Counselling:</h3>
      <p>
        Navigating the complexities of depression with a supportive and
        empathetic approach, Criya Samvad empowers individuals to rediscover joy
        and purpose.
      </p>
    </section>
    <section>
      <h3>Neuro Linguistic Programming (NLP):</h3>
      <p>
        Harnessing the power of language and thought patterns to achieve
        personal and professional goals, Criya Samvad unlocks full potential.
      </p>
    </section>
    <section>
      <h3>Ayurvedic Psychotherapy:</h3>
      <p>
        Integrating the ancient wisdom of Ayurveda with modern psychotherapy
        techniques for holistic mental well-being.
      </p>
    </section>
    <section>
      <h3>Human Performance Coach and Life Strategist:</h3>
      <p>
        Maximising potential, both personally and professionally, Criya Samvad
        provides strategies designed to enhance overall performance and
        fulfilment.
      </p>
    </section>
    <section>
      <h2>Why Choose Criya Samvad?</h2>
      <h3>Holistic Approach:</h3>
      <p>
        Criya Samvad believes in addressing the mind, body, and spirit for
        comprehensive well-being. Sessions encompass various proven
        methodologies to ensure a holistic approach to personal growth.
      </p>
    </section>
    <section>
      <h3>Tailored Solutions:</h3>
      <p>
        Every individual and relationship is unique. Criya Samvad customises
        their approach to meet specific needs, ensuring that sessions are both
        impactful and relevant to goals.
      </p>
    </section>
    <section>
      <h3>Professional Expertise:</h3>
      <p>
        With extensive training and experience in diverse therapeutic
        modalities, Criya Samvad brings a wealth of knowledge to guide
        individuals toward positive transformation.
      </p>
    </section>
    <section>
      <h3>Confidentiality and Trust:</h3>
      <p>
        Privacy and trust are of utmost importance. Rest assured, sessions with
        Criya Samvad provide a safe and confidential space for individuals to
        explore and grow.
      </p>
    </section>
    <section>
      <p>Ready to embark on a transformative journey?</p>
    </section>
    <section>
      <p> Contact Criya Samvad today to schedule the first session and take the
      first step toward mastering life.</p>
    </section>
  </main>
  
</>
<Footer />
    </div>
  )
}

export default First_page