import React from 'react'
import './first-page.css'
import Event from "../assets/Hero/Event Spaces.jpg";
import Footer from '../Common/Footer/Footer';
import Navbar from '../Common/Navbar/Navbar';

const Eighth_page = () => {
  return (
    <div>
      <Navbar />
    <>
<header>
  <h1>Criya Hub</h1>
  <h3>Empowering Individuals, Resolving Conflicts, and Cultivating Growth</h3>
</header>
<img src={Event} alt="Large Image" />
<main>
  <section>
    <h3>Welcome to <span className='heading-1'> Criya Versatile Event Spaces </span></h3>
    {/* <h2>Unlocking the Potential of Relationships and Personal Growth</h2> */}
    <p>
    At Criya, we believe that every event is unique and deserves a space that perfectly complements its essence. Whether you're hosting a corporate gathering, a wedding celebration, a product launch, or a social event, we offer versatile event spaces tailored to meet your needs. Our commitment is to provide you with the ideal environment where your vision can flourish and your guests can create lasting memories.
    </p>
  </section>
  <section>
    <h2>Our Spaces</h2>
    <h3>1.	The Grand Hall:  </h3>
    <p>
    Our flagship space, the Grand Hall, is an elegant and spacious venue perfect for large-scale events such as weddings, galas, and conferences. With its high ceilings, modern amenities, and customizable layout, the Grand Hall can accommodate up to 500 guests, making it the ideal choice for those looking to make a grand impression.
    </p>
  </section>
  <section>
    <h3>2.	The Garden Terrace:  </h3>
    <p>
    For those seeking an outdoor ambiance, our Garden Terrace offers a picturesque setting surrounded by lush greenery and stunning views. Whether you're planning a cocktail party, a corporate retreat, or a romantic wedding ceremony, the Garden Terrace provides a serene atmosphere that will elevate any occasion.

    </p>
  </section>
  <section>
    <h3>3.	The Executive Lounge:  </h3>
    <p>
    Designed for more intimate gatherings, the Executive Lounge exudes sophistication and style. With its contemporary decor, cosy seating areas, and state-of-the-art audiovisual equipment, this space is ideal for board meetings, networking events, and private dinners.

    </p>
  </section>
  <section>
    <h3>4.	The Creative Studio:   </h3>
    <p>
    Need a space to unleash your creativity? Look no further than our Creative Studio, a versatile environment perfect for workshops, art exhibitions, and brainstorming sessions. With its flexible layout and abundance of natural light, the Creative Studio provides the perfect canvas for your ideas to flourish.

    </p>
  </section>
  <section>
  <h2> Our Services</h2>
    <h3>1.	Event Planning Assistance: </h3>
    <p>
    Our experienced event planning team is dedicated to bringing your vision to life. From concept development to execution, we'll work closely with you every step of the way to ensure that your event is a resounding success.

    </p>
  </section>
  
  <section>
  

    <h3>2.	Catering Services:  </h3>
    <p>
    Treat your guests to a culinary experience they won't soon forget with our customizable catering options. Whether you prefer plated dinners, buffet-style service, or interactive food stations, our talented chefs will create mouthwatering menus tailored to your tastes.
    </p>
  </section>
  <section>
    <h3>3.	Audiovisual Support:  </h3>
    <p>
    Leave the technical details to us with our comprehensive audiovisual support services. From sound systems and lighting design to projection equipment and stage setup, we'll ensure that your event looks and sounds flawless.
    </p>
  </section>
  <section>

    <h3>4.	Event Coordination:   </h3>
    <p>
    On the day of your event, our dedicated event coordinators will be on hand to oversee every detail, allowing you to relax and enjoy the occasion knowing that everything is taken care of.
    </p>
  </section>
  <section>
  <h2>Why Choose Criya?</h2>

    <h3>-	Flexibility:  </h3>
    <p>
    With a variety of versatile event spaces to choose from, you'll have the freedom to design the perfect setting for your event.
    </p>
  </section>
  <section>
    <h3>-	Attention to Detail:    </h3>
    <p>
    From the moment you contact us to the final moments of your event, we'll provide personalised attention to ensure that every aspect exceeds your expectations.
    </p>
  </section>
 
  <section>
    <h3>-	Exceptional Service: </h3>
    <p>
    Our team is committed to providing exceptional service at every turn, ensuring that your event is not only memorable but also stress-free.
    </p>
  </section>
  <section>
    {/* <h2>Join Us:</h2> */}
    <p>
    Whether you're hosting a corporate conference, a milestone celebration, or a creative workshop, Criya Versatile Event Spaces is here to make your vision a reality. Contact us today to learn more about our spaces and services, and let's start planning an event to remember.
    </p>
  </section>
</main>

</>
<Footer />

  </div>  )  
}

export default Eighth_page