import React from 'react';
import Logo from '../../assets/logos/Criya Hub White BG.jpg';
import './start.css';
import { Link } from 'react-router-dom';

const Start = () => {
  return (
    <div className='criya_container'>
       <Link to="/home" className='image-wrapper'><img src={Logo} alt="Logo" /> </Link> 
    </div>
  )
}

export default Start;