import React from 'react';
import "./terms.css";
import Footer from '../../Common/Footer/Footer';
import Navbar from '../../Common/Navbar/Navbar';

const Terms = () => {
  return (
    <div>
        <Navbar />
    <div className='criyahub-container'>
        <h1 className='criyahub-container_mainheading'>Terms and Conditions</h1>

        <h2 className='criyahub-container-subheading'>1. Definitions:</h2>
        <p className='criyahub-container_para'>"Firm," "we," "us," or "our" refers to Criya Hub Private Limited, a company registered in India offering Life Coaching services, Legal Services, Mediation and Dispute Resolution Services, CA Services, Content Production Space, Coworking Space, Event Spaces and Incubation Space for Startups.</p>
        <p className='criyahub-container_para'>"User," "you," or "your" refers to any individual or entity accessing or using our services.</p>
        <p className='criyahub-container_para'> "Services" collectively refer to the range of services provided by our firm, including but not limited to life coaching, legal services, mediation and dispute resolution services, CA services, content production space, coworking space, event spaces, and incubation space for startups.</p>
        
        <h2 className='criyahub-container-subheading'>2. Scope of Services:</h2>
        <p className='criyahub-container_para'>Our firm offers a comprehensive range of services designed to cater to the diverse needs of individuals and businesses in India.</p>

        <h2 className='criyahub-container-subheading'>3. User Eligibility:</h2>
        <p className='criyahub-container_para'>By accessing or using our services, you represent and warrant that you are legally eligible to do so in accordance with Indian laws and regulations.</p>

        <h2 className='criyahub-container-subheading'>4. Registration:</h2>
        <p className='criyahub-container_para'>   Some of our services may require user registration. You agree to provide accurate and complete information during the registration process and to update such information as necessary to keep it current.</p>

        <h2 className='criyahub-container-subheading'>5. User Responsibilities:</h2>
        <p className='criyahub-container_para'>- You are responsible for maintaining the confidentiality of your account information and for all activities that occur under your account.</p>
        <p className='criyahub-container_para'>- You agree to comply with all applicable laws and regulations while using our services.</p> 

        <h2 className='criyahub-container-subheading'>6. Payment Terms:</h2>
        <p className='criyahub-container_para'>- Payment for our services is subject to the terms agreed upon at the time of purchase or engagement.</p>
        <p className='criyahub-container_para'>- All fees and charges are non-refundable unless otherwise stated.</p>

        <h2 className='criyahub-container-subheading'>7. Cancellation and Refund Policy:</h2>
        <p className='criyahub-container_para'>- Cancellation of services may be subject to specific terms and conditions outlined in individual service agreements.</p>
        <p className='criyahub-container_para'>- Refunds, if applicable, will be processed in accordance with our refund policy.</p>

        <h2 className='criyahub-container-subheading'>8. Intellectual Property:</h2>
        <p className='criyahub-container_para'>- All intellectual property rights related to our services, including but not limited to content, trademarks, and logos, are owned or licensed by our firm.</p>
        <p className='criyahub-container_para'>- Users may not use, copy, or distribute any proprietary information without prior written consent.</p>

        <h2 className='criyahub-container-subheading'>9. Confidentiality:</h2>
        <p className='criyahub-container_para'>- We respect the privacy of our users and will handle all confidential information in accordance with our Privacy Policy.</p>

        <h2 className='criyahub-container-subheading'>10. Dispute Resolution:</h2>
        <p className='criyahub-container_para'>- Any disputes arising from or relating to these terms and conditions shall be resolved through negotiation, mediation, or arbitration in accordance with Indian laws.</p>

        <h2 className='criyahub-container-subheading'>11. Limitation of Liability:</h2>
        <p className='criyahub-container_para'>- Our firm shall not be liable for any indirect, incidental, special, or consequential damages arising from or in connection with the use of our services.</p>

        <h2 className='criyahub-container-subheading'>12. Termination:</h2>
        <p className='criyahub-container_para'>- We reserve the right to terminate or suspend your access to our services at any time and for any reason, without prior notice.</p>

        <h2 className='criyahub-container-subheading'>13. Changes to Terms and Conditions:</h2>
        <p className='criyahub-container_para'>- We reserve the right to modify these terms and conditions at any time. Any changes will be effective immediately upon posting on our website.</p>

        <h2 className='criyahub-container-subheading'>14. Governing Law:</h2>
        <p className='criyahub-container_para'>- These terms and conditions shall be governed by and construed in accordance with the laws of India.</p>

        <h2 className='criyahub-container-subheading'>15. Contact Information:</h2>
        <p className='criyahub-container_para'>- For questions or concerns regarding these terms and conditions or our services, please contact us at contact@criyahub.com.</p>

        <p className='criyahub-container_para'>By accessing or using our services, you agree to be bound by these terms and conditions. If you do not agree with any part of these terms, you may not use our services.</p>
        <p className='criyahub-container_paralast'>Thank you for choosing Criya Hub Private Limited for your service needs.</p>
        
    </div>
        <Footer />
    </div>
  )
}

export default Terms;