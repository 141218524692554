import React from 'react'
import './first-page.css'
import Dispute from "../assets/Hero/Dispute Resolution and Mediation.jpg";
import Navbar from '../Common/Navbar/Navbar';
import Footer from '../Common/Footer/Footer';

const Third_page = () => {
  return (
    <div>
      <Navbar />
    <>
<header>
  <h1>Criya Hub</h1>
  <h3>Empowering Individuals, Resolving Conflicts, and Cultivating Growth</h3>
</header>
<img src={Dispute} alt="Large Image" />
<main>
  <section>
    <h3>Welcome to <span className='heading-1'> Criya Dispute Resolution and Mediation Services </span></h3>
    {/* <h2>Unlocking the Potential of Relationships and Personal Growth</h2> */}
    <p>
    At Criya Conflict Resolution and Mediation Services, we understand that conflicts arise in various aspects of life, whether it's in personal relationships, professional environments, or within communities. Our mission is to provide effective, impartial, and confidential conflict resolution services to help parties find amicable solutions and move forward positively.
    </p>
  </section>
  <section>
    <h2>Our Services:</h2>
    <h3>1.	Mediation: </h3>
    <p>
    Our experienced mediators facilitate constructive dialogue between conflicting parties to help them reach mutually acceptable agreements. Whether it's a disagreement between family members, coworkers, neighbours, or business partners, we provide a neutral space for productive communication and negotiation
    </p>
  </section>
  <section>
    <h3>2.	Conflict Coaching: </h3>
    <p>
    Sometimes, individuals may need assistance in managing conflicts on a personal level. Our conflict coaches offer one-on-one support to help clients develop effective communication skills, manage emotions, and explore strategies for resolving conflicts in their daily lives.
    </p>
  </section>
  <section>
    <h3>3.	Workplace Conflict Resolution: </h3>
    <p>
    Conflict in the workplace can disrupt productivity and morale. Our tailored conflict resolution services for businesses and organisations aim to address workplace disputes efficiently, minimise disruption, and promote a positive work environment.
    </p>
  </section>
  <section>
    <h3>4.	Community Mediation: </h3>
    <p>
    Communities thrive when conflicts are resolved peacefully. We work with community members, organisations, and local authorities to address community-level disputes and promote harmony and understanding among residents.
    </p>
  </section>
  <section>
    <h3>5.	Training and Workshops: </h3>
    <p>
    We offer customised training programs and workshops on conflict resolution, communication skills, and mediation techniques. These sessions are designed for individuals, businesses, educational institutions, and community groups seeking to enhance their conflict resolution capabilities.
    </p>
  </section>
  
  <section>
    <h2>Why Choose Criya </h2>
    <h3>-	Expertise: </h3>
    <p>
    Our team consists of trained and experienced mediators and conflict resolution specialists committed to facilitating fair and lasting solutions.
    </p>
  </section>
  <section>
    <h3>-	Confidentiality: </h3>
    <p>
    We prioritize confidentiality and ensure that all discussions and information shared during the mediation process remain private and secure.
    </p>
  </section>
  <section>
    <h3>-	Impartiality: </h3>
    <p>
    As neutral third-party facilitators, we maintain impartiality and do not take sides in conflicts. Our focus is solely on helping parties find mutually beneficial solutions.
    </p>
  </section>
  <section>
    <h3>-	Client-Centered Approach: </h3>
    <p>
    We understand that each conflict is unique, and we tailor our approach to meet the specific needs and preferences of our clients.
    </p>
  </section>
  <section>
    <h3>-	Positive Outcomes: </h3>
    <p>
    Our goal is to assist parties in finding sustainable resolutions that address their underlying interests and promote long-term harmony and cooperation.
    </p>
  </section>
  <section>
    <h2>Contact Us:</h2>
    <p>
    If you're facing a conflict and seeking assistance, don't hesitate to reach out to Criya Conflict Resolution and Mediation Services. Contact us today to learn more about how we can help you navigate your dispute and find a constructive resolution.
    </p>
  </section>
</main>
</>
<Footer />

  </div>
  )
}

export default Third_page