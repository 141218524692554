import React from 'react';
import './first-page.css';
import Incubation from "../assets/Hero/Incubation for Startups.jpg";
import Navbar from '../Common/Navbar/Navbar';
import Footer from '../Common/Footer/Footer';

const Seventh_page = () => {
  return (
    <div>
      <Navbar />
    <>
<header>
  <h1>Criya Hub</h1>
  <h3>Empowering Individuals, Resolving Conflicts, and Cultivating Growth</h3>
</header>
<img src={Incubation} alt="Large Image" />
<main>
  <section>
    <h3>Welcome to <span className='heading-1'> Criya Incubation Platform for Startups </span></h3>
    {/* <h2>Unlocking the Potential of Relationships and Personal Growth</h2> */}
    <p>
    At Criya, we believe in nurturing innovative ideas and empowering entrepreneurs to transform their visions into successful businesses. Our incubation platform provides comprehensive support and resources tailored to the unique needs of startups at every stage of their journey. Whether you're just starting out or looking to scale, Criya is here to help you thrive.
    </p>
  </section>
  <section>
    <h2>What Sets Us Apart:</h2>
    <h3>1.	Holistic Support:  </h3>
    <p>
    We offer more than just office space. Our team provides guidance and mentorship across various aspects of business development, including strategy, marketing, finance, and technology.
    </p>
  </section>
  <section>
    <h3>2.	Tailored Programs: </h3>
    <p>
    Our incubation programs are designed to meet the specific needs of different industries and stages of growth. From ideation to market entry and beyond, we have a program to support you at every step.

    </p>
  </section>
  <section>
    <h3>3.	Access to Networks:  </h3>
    <p>
    Through our extensive network of industry experts, investors, and corporate partners, startups on our platform gain valuable connections and opportunities for collaboration and growth

    </p>
  </section>
  <section>
    <h3>4.	State-of-the-Art Facilities:  </h3>
    <p>
    Our modern, fully-equipped workspace provides startups with the resources they need to focus on innovation without worrying about the logistics of running an office.

    </p>
  </section>
  <section>
    <h3>5.	Flexible Funding Options: </h3>
    <p>
    We understand that every startup is unique, which is why we offer flexible funding options tailored to the needs of each venture, including seed funding, grants, and access to investor networks.

    </p>
  </section>
  
  <section>
  <h2> Our Services</h2>

    <h3>1.	Mentorship and Coaching:  </h3>
    <p>
    Our experienced mentors provide one-on-one guidance and coaching to help startups navigate challenges, refine their business strategies, and unlock their full potential.
    </p>
  </section>
  <section>
    <h3>2.	Networking Events and Workshops:  </h3>
    <p>
    We regularly host networking events, workshops, and industry-specific seminars to facilitate knowledge sharing, collaboration, and skill development among our community of startups.
    </p>
  </section>
  <section>
  {/* <h2>Why Choose Criya Media Production Space? </h2> */}

    <h3>3.	Access to Resources:  </h3>
    <p>
    From legal and accounting services to marketing and design support, we connect startups with the resources they need to accelerate their growth and achieve their goals.
    </p>
  </section>
  <section>
    <h3>4.	Investor Pitching:  </h3>
    <p>
    We help startups prepare for investor pitches and connect them with potential investors who are aligned with their vision and goals.
    </p>
  </section>
  <section>
    <h3>5.	Market Access:   </h3>
    <p>
    Through our partnerships with leading corporations and industry players, we help startups access new markets, pilot their products, and forge strategic partnerships.
    </p>
  </section>
 
  <section>
    <h2>Join Us:</h2>
    <p>
    If you're a passionate entrepreneur with a groundbreaking idea, we invite you to join the Criya Incubation Platform and embark on a journey of innovation, growth, and success. Together, we'll turn your vision into reality.
    </p>
  </section>
  <section>
    {/* <h2>Join Us:</h2> */}
    <p>
    Contact us today to learn more about our programs and how we can support your startup's journey to success.
    </p>
  </section>
</main>

</>
<Footer />
  </div>  )
}

export default Seventh_page