import React, { useState } from "react";
import { NavLink, Link } from "react-router-dom";
import "./navbar.css";
import { FaBars } from "react-icons/fa";
import { BsXLg } from "react-icons/bs";
import logo from "../../assets/logos/Criya Hub Black BG.jpg";

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);
  const [toggleDropdown, setToggleDropdown] = useState(false);

  return (
    <div className="criya__navbar">
      <div className="criya__navbar-links">
        <div className="criya__navbar-links_logo_container">
          <Link to="/" className="criya__navbar_logo">
            <img src={logo} alt="" />
          </Link>
        </div>
        <div className="criya__navbar-links_container">
          <li className="criya__navbar-links_li">
            <NavLink to="/">Home</NavLink>
          </li>
          <li className="criya__navbar-links_li">
            <NavLink to="/ourstory">Our Story</NavLink>
          </li>
          <li className="criya__navbar-links_li">
            <NavLink to="/ourservices">Our Services</NavLink>
          </li>
          <li className="criya__navbar-links_li">
            <NavLink to="/contact">Contact Us</NavLink>
          </li>
        </div>
      </div>
      <div className="criya__navbar-menu">
        {toggleMenu ? (
          <BsXLg
            className="criya__navbar-menu_toggleBtn"
            color="var(--color-navbar-links)"
            size={20}
            onClick={() => setToggleMenu(false)}
          />
        ) : (
          <FaBars
            className="criya__navbar-menu_toggleBtn"
            color="var(--color-navbar-links)"
            size={25}
            onClick={() => setToggleMenu(true)}
          />
        )}
        {toggleMenu && (
          <div className="criya__navbar-menu_container">
            <div className="criya__navbar-menu_container-links">
              <li
                onClick={() => {
                  setToggleMenu(false);
                  setToggleDropdown(false);
                }}
              >
                <NavLink to="/">Home</NavLink>
              </li>
              <li
                onClick={() => {
                  setToggleMenu(false);
                  setToggleDropdown(false);
                }}
              >
                <NavLink to="/ourstory">Our Story</NavLink>
              </li>
              <li
                onClick={() => {
                  setToggleMenu(false);
                  setToggleDropdown(false);
                }}
              >
                <NavLink to="/ourservices">Our Services</NavLink>
              </li>
            
              <li
                onClick={() => {
                  setToggleMenu(false);
                  setToggleDropdown(false);
                }}
              >
                <NavLink to="/contact">Contact Us</NavLink>
              </li>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Navbar;