import React from 'react';
import './first-page.css';
import Coworking from "../assets/Hero/CoWorking Spaces.jpg";
import Navbar from '../Common/Navbar/Navbar';
import Footer from '../Common/Footer/Footer';

const Sixth = () => {
  return (
    <div>
      <Navbar />
    <>
<header>
  <h1>Criya Hub</h1>
  <h3>Empowering Individuals, Resolving Conflicts, and Cultivating Growth</h3>
</header>
<img src={Coworking} alt="Large Image" />
<main>
  <section>
    <h3>Welcome to <span className='heading-1'> Criya Co-Working Spaces </span></h3>
    {/* <h2>Unlocking the Potential of Relationships and Personal Growth</h2> */}
    <p>
    At Criya, we believe in the power of collaboration, creativity, and community. Our co-working spaces are designed to foster innovation, productivity, and networking opportunities for individuals and teams from diverse backgrounds. Whether you're a freelancer, entrepreneur, startup, or remote worker, Criya provides the perfect environment for you to thrive.
    </p>
  </section>
  <section>
    <h2>Why Choose Criya?</h2>
    <h3>1.	Flexible Membership Plans: </h3>
    <p>
    We offer flexible membership options to suit your needs, whether you require a dedicated desk, a private office, or just need occasional access to our facilities.

    </p>
  </section>
  <section>
    <h3>2.	State-of-the-Art Facilities: </h3>
    <p>
    Our spaces are equipped with high-speed internet, ergonomic furniture, meeting rooms, and amenities such as printing services, kitchen facilities, and relaxation areas.

    </p>
  </section>
  <section>
    <h3>3.	Community & Networking:  </h3>
    <p>
    Joining Criya means becoming part of a vibrant community of like-minded individuals. Attend networking events, workshops, and seminars to connect, collaborate, and grow your network.

    </p>
  </section>
  <section>
    <h3>4.	Location Convenience:  </h3>
    <p>
    With our location in the prime business district, Criya provides convenient access to transportation, restaurants, and other essential services.

    </p>
  </section>
  <section>
    <h3>5.	Supportive Environment: </h3>
    <p>
    Whether you need business advice, mentorship, or just a friendly face, our supportive staff and community members are here to help you succeed.

    </p>
  </section>
  
  <section>
  <h2> Our Services</h2>

    <h3>1.	Co-Working Spaces:  </h3>
    <p>
    Choose from open-plan workspaces, dedicated desks, or private offices tailored to your preferences and budget.
    </p>
  </section>
  <section>
    <h3>2.	Meeting & Event Spaces:   </h3>
    <p>
    Host meetings, workshops, seminars, and networking events in our fully equipped meeting rooms and event spaces.
    </p>
  </section>
  <section>

    <h3>3.	Virtual Office Services: </h3>
    <p>
    Benefit from our virtual office services, including mail handling, phone answering, and access to our business address for your professional needs.
    </p>
  </section>
  <section>
    <h3>4.	Business Support:  </h3>
    <p>
    Take advantage of our business support services, including mentorship, workshops, and access to resources to help you grow your venture.
    </p>
  </section>
 
 
  <section>
    <h2>Join the Criya Community Today!</h2>
    <p>
    Ready to take your work to the next level? Join Criya and experience the benefits of collaborative co-working spaces. Whether you're looking for a place to work, meet, or connect, Criya has everything you need to thrive. Sign up for a tour today and discover your new workspace!
    </p>
  </section>
</main>

</>
<Footer />
  </div>
  )
}

export default Sixth