import React from 'react'
import './first-page.css';
import Content from "../assets/Hero/Content Production Space.jpg";
import Navbar from '../Common/Navbar/Navbar';
import Footer from '../Common/Footer/Footer';

const Fifth_page = () => {
  return (
    <div>
      <Navbar />
    <>
<header>
  <h1>Criya Hub</h1>
  <h3>Empowering Individuals, Resolving Conflicts, and Cultivating Growth</h3>
</header>
<img src={Content} alt="Large Image" />
<main>
  <section>
    <h3>Welcome to <span className='heading-1'> Criya Content Production Space </span></h3>
    {/* <h2>Unlocking the Potential of Relationships and Personal Growth</h2> */}
    <p>
    At Criya Media Production Space, we provide a dynamic and innovative environment for creators to bring their ideas to life. Our state-of-the-art facilities are designed to cater to the diverse needs of content creators, offering everything from studio space to cutting-edge equipment and expert support services. Whether you're a seasoned professional or just starting out, Criya Media Production Space is the perfect place to unleash your creativity and produce high-quality content for today's digital world.
    </p>
  </section>
  <section>
    <h2>Facilities:</h2>
    <h3>-	Studio Space: </h3>
    <p>
    Our spacious studios are equipped with professional lighting, soundproofing, and green screen capabilities, providing the ideal setting for photo shoots, video productions, live streams, and more.

    </p>
  </section>
  <section>
    <h3>-	Equipment Rental: </h3>
    <p>
    From cameras and microphones to lighting rigs and editing suites, we offer a wide range of industry-standard equipment available for rent at competitive rates.

    </p>
  </section>
  <section>
    <h3>-	Editing Suites: </h3>
    <p>
    Take advantage of our fully-equipped editing suites featuring the latest software and hardware, allowing you to polish your content to perfection.

    </p>
  </section>
  <section>
    <h3>-	Live Streaming Setup: </h3>
    <p>
    Engage with your audience in real-time with our dedicated live streaming setup, complete with high-definition cameras, professional audio equipment, and seamless streaming capabilities.

    </p>
  </section>
  <section>
  <h2>Services</h2>
    <h3>-	Technical Support: </h3>
    <p>
    Our team of experienced technicians is on hand to provide technical assistance and guidance throughout the production process, ensuring that your project runs smoothly from start to finish.

    </p>
  </section>
  
  <section>
    <h3>-	Creative Consultation: </h3>
    <p>
    Need help refining your concept or developing a creative strategy? Our team of experts is here to offer personalised consultation services to help bring your vision to life.
    </p>
  </section>
  <section>
    <h3>-	Post-Production Services:  </h3>
    <p>
    Let our skilled editors and post-production specialists add the finishing touches to your content, including colour grading, audio mastering, and special effects.
    </p>
  </section>
  <section>
  <h2>Why Choose Criya Media Production Space? </h2>

    <h3>-	Flexibility: </h3>
    <p>
    Whether you're working on a solo project or collaborating with a team, our flexible booking options and customizable packages make it easy to find the right solution for your needs.
    </p>
  </section>
  <section>
    <h3>-	Quality:  </h3>
    <p>
    With top-of-the-line equipment and expert support services, you can trust Criya Media Production Space to deliver high-quality results that meet your standards and exceed your expectations.
    </p>
  </section>
  <section>
    <h3>-	Community:   </h3>
    <p>
    Join a vibrant community of creators and industry professionals, network with like-minded individuals, and gain access to exclusive events, workshops, and resources designed to help you succeed in the world of media production.
    </p>
  </section>
 
  <section>
    <h2>Get Started Today!</h2>
    <p>
    Ready to take your media production to the next level? Contact Criya Media Production Space to schedule a tour, book a studio, or learn more about our services and facilities. Whether you're shooting a YouTube video, recording a podcast, or streaming a live event, we have everything you need to bring your vision to life. Start creating with Criya Media Production Space today!
    </p>
  </section>
</main>

</>
<Footer />

  </div>
  )
}

export default Fifth_page