import React from 'react';
import './first-page.css';
import CA from "../assets/Hero/CA Services.jpg";
import Navbar from '../Common/Navbar/Navbar';
import Footer from '../Common/Footer/Footer';

const Fourth_page = () => {
  return (
    <div>
      <Navbar />
    <>
<header>
  <h1>Criya Hub</h1>
  <h3>Empowering Individuals, Resolving Conflicts, and Cultivating Growth</h3>
</header>
<img src={CA} alt="Large Image" />
<main>
  <section>
    <h3>Welcome to <span className='heading-1'> Criya CA Services </span></h3>
    {/* <h2>Unlocking the Potential of Relationships and Personal Growth</h2> */}
    <p>
    At Criya CA Services, we pride ourselves on being your trusted partner in navigating the complexities of financial and regulatory compliance. With a team of seasoned professionals and a commitment to excellence, we offer a comprehensive suite of services tailored to meet your unique needs.
    </p>
  </section>
  <section>
    <h2>Our Services:</h2>
    <h3>1. Taxation Services: </h3>
    <p>
    -	Personal Income Tax Filing  <br />
-	Corporate Tax Planning and Compliance<br />
-	GST Registration and Filing - Tax Audits and Assessments

    </p>
  </section>
  <section>
    <h3>2. Accounting and Bookkeeping:</h3>
    <p>
    -	Financial Statement Preparation<br />
-	General Ledger Maintenance<br />
-	Payroll Processing - Budgeting and Forecasting

    </p>
  </section>
  <section>
    <h3>3. Audit and Assurance:</h3>
    <p>
    -	Statutory Audits<br />
-	Internal Audits<br />
-	Compliance Audits - Due Diligence Reviews

    </p>
  </section>
  <section>
    <h3>4. Business Advisory:</h3>
    <p>
    -	Entity Selection and Structuring<br />
-	Financial Planning and Analysis<br />
-	Business Valuation<br />
-	Risk Management<br />

    </p>
  </section>
  <section>
    <h3>5. Company Registration and Compliance:</h3>
    <p>
    -	Incorporation Services<br />
-	Annual Compliance Filings<br />
-	Secretarial Services<br />
-	Regulatory Compliance Checks

    </p>
  </section>
  
  <section>
    <h2>Why Choose Us: </h2>
    <h3>-	Expertise: </h3>
    <p>
    Our team comprises experienced Chartered Accountants with in-depth knowledge across various industries.
    </p>
  </section>
  <section>
    <h3>-	Customized Solutions:  </h3>
    <p>
    We understand that every client is unique, and we tailor our services to address your specific requirements.
    </p>
  </section>
  <section>
    <h3>-	Timely Delivery: </h3>
    <p>
    We prioritize deadlines and ensure timely completion of tasks without compromising quality.
    </p>
  </section>
  <section>
    <h3>-	Ethical Standards:  </h3>
    <p>
    Integrity and professionalism are at the core of everything we do. You can trust us to uphold the highest ethical standards in our dealings.
    </p>
  </section>
 
  <section>
    <h2>Get In Touch:</h2>
    <p>
    Ready to take the next step towards financial clarity and compliance excellence? Contact us today to schedule a consultation with one of our experts. Let Criya CA Services be your trusted partner on your journey to success.
    </p>
  </section>
</main>

</>
<Footer />

  </div>
  )
}

export default Fourth_page