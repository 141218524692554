import React from "react";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./Pages/Home";
import Contact from "./Pages/Contact";
import Services from "./Pages/Services";
import Story from "./Pages/ourStory";
import Start from "./Pages/Start/Start";
import Life_coaching from "./Pages/Life_coaching";
import Legal_services from "./Pages/Legal_services";
import Dispute_resolution from "./Pages/Dispute_resolution";
import CA_services from "./Pages/CA_services";
import Content_production from "./Pages/Content_production";
import Co_working from "./Pages/Co_working";
import Incubation from "./Pages/Incubation";
import Event_space from "./Pages/Event_space";
import Terms from "./Pages/terms/Terms";


const App = () => {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Start />} />
          <Route path="/home" element={<Home />} />
          <Route path="/ourstory" element={<Story />} />
          <Route path="/ourservices" element={<Services />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/life-coaching" element={<Life_coaching />} />
          <Route path="/legal-services" element={<Legal_services />} />
          <Route path="/dispute-resolution" element={<Dispute_resolution />} />
          <Route path="/ca-services" element={<CA_services />} />
          <Route path="/content-production" element={<Content_production />} />
          <Route path="/co-working" element={<Co_working />} />
          <Route path="/incubation" element={<Incubation />} />
          <Route path="/event-space" element={<Event_space />} />
          <Route path="/terms" element={<Terms />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default App;