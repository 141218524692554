import React from 'react';
import ImageCard from '../Components/Home/ImageCard/ImageCard';
import Navbar from '../Common/Navbar/Navbar';
import Footer from '../Common/Footer/Footer';

const Home = () => {
  return (
    <div>
        <Navbar />
        <ImageCard />
        <Footer />
    </div>
  )
}

export default Home;