import React from 'react';
import './imageCard.css';
import LifeCoaching from '../../../assets/images/Services/LIFE COACHING.png';
import LegalServices from '../../../assets/images/Services/LEGAL SERVICES.png';
import Dispute from '../../../assets/images/Services/Dispute Resolution.png';
import CAServices from '../../../assets/images/Services/CHARATERED ACCOUNTANT SERVICES.png';
import Content from '../../../assets/images/Services/PRODUCTION (1).png';
import Coworking from '../../../assets/images/Services/SPACES.png';
import Incubation from '../../../assets/images/for Startups.png';
import Event from '../../../assets/images/Event Spaces.png';
import { Link } from 'react-router-dom';

const ImageCard = () => {

    const images = [
      { src: LifeCoaching, link:'/life-coaching',alt: 'Life Coaching', },
      {src: LegalServices,link:'/legal-services', alt: 'Legal Services'},
      {src: Dispute,link:'/dispute-resolution',  alt: 'Dispute Resolution'},
      {src: CAServices, link:'/ca-services',  alt: 'Ca Services'},
      {src: Content,link:'/content-production',  alt: 'Content Production Space'},
      {src: Coworking, link:'/co-working',  alt: 'Co Working Space'},
      { src: Incubation,link:'/incubation', alt: 'Incubation for Startups' },
      {src: Event, link:'/event-space',alt: 'Event Spaces'},
        
      ];

  return (
    <div className='section'>
    <div className="image-grid">
      {images.map((image, index) => (
        <Link to={image.link}>
        <img
          key={index}
          src={image.src} 
          alt={image.alt || `Image ${index + 1}`}
          className="image-item"
        />
        </Link>
      ))}
    </div>
    </div>
  )
}

export default ImageCard;