import React from 'react'
import './first-page.css'
import Legal from "../assets/Hero/Legal Services.jpg";
import Navbar from '../Common/Navbar/Navbar';
import Footer from '../Common/Footer/Footer';
const Second_page = () => {
  return (
    <div>
      <Navbar />
    <>
<header>
  <h1>Criya Hub</h1>
  <h3>Empowering Individuals, Resolving Conflicts, and Cultivating Growth</h3>
</header>
<img src= {Legal} alt="Large Image" />
<main>
  <section>
    <h3>Welcome to <span className='heading-1'> Criya Pragya - </span>Your Legal Partners at  <span className='heading-1'> Criya Hub </span></h3>
    {/* <h2>Unlocking the Potential of Relationships and Personal Growth</h2> */}
    <p>
    At Criya Pragya, we are dedicated to providing comprehensive legal solutions tailored to meet the diverse needs of our clients in India. As part of Criya Hub, our law firm brings a wealth of expertise to address a wide spectrum of legal matters. From corporate and commercial law to family disputes, taxation, and beyond, we are committed to delivering exceptional legal services with a focus on integrity, excellence, and client satisfaction.
    </p>
  </section>
  <section>
    <h2>Our Services:</h2>
    <h3>Legal Consultation and Advisory:</h3>
    <p>
    ●	General legal advice. <br /> ●	Due diligence.<br />
●	Legal opinions

    </p>
  </section>
  <section>
    <h3>Family Law:</h3>
    <p>
    ●	Divorce and separation. <br />
●	Child custody and support.<br />
●	Adoption.<br />
●	Domestic violence matters.

    </p>
  </section>
  <section>
    <h3>Criminal Law:</h3>
    <p>
    ●	Defence in criminal cases.<br />
●	Bail applications.<br />
●	Legal representation in court.

    </p>
  </section>
  <section>
    <h3>Corporate and Commercial Law:</h3>
    <p>
    ●	Business formation and structuring. <br /> ●	Contract drafting and negotiation.<br />
●	Corporate governance and compliance.<br /> ●	Mergers and acquisitions.

    </p>
  </section>
  <section>
    <h3>Real Estate and Property Law:</h3>
    <p>
    ●	Property acquisition and due diligence.<br />
●	Real estate transactions.<br />
●	Lease agreements.<br />
●	Title verification.

    </p>
  </section>
  <section>
    <h3>Intellectual Property Law:</h3>
    <p>
    ●	Trademark registration and protection.<br />
●	Patent filing and prosecution.<br /> ●	Copyright registration.<br />
●	Intellectual property litigation.

    </p>
  </section>
  <section>
    <h3>Labour and Employment Law:</h3>
    <p>
    ●	Employment contracts & agreements.<br />
●	Workplace disputes.<br />
●	Labour law compliance.<br />
●	Employee rights and benefits.

    </p>
  </section>
  <section>
    <h3>Banking and Finance Law:</h3>
    <p>
    ●	Loan documentation.<br />
●	Debt recovery.<br />
●	Regulatory compliance. <br />●	Banking litigation.

    </p>
  </section>
  <section>
    <h3>Cyber Law:</h3>
    <p>
    ●	Data protection and privacy.<br />
●	Cybercrime defence.<br />
●	E-commerce regulations.<br /> ●	Technology contracts.

    </p>
  </section>
  <section>
    <h3>Environmental Law:</h3>
    <p>
    	●	Environmental compliance.<br />
●	Pollution control and environmental litigation.<br /> ●	Land use and zoning.


    </p>
  </section>
  <section>
    <h3>Immigration Law:</h3>
    <p>
    	●	Visa and work permit applications.<br />
●	Citizenship matters.<br />
●	Deportation defence.


    </p>
  </section>
  <section>
    <h2>Why Choose Criya Pragya?</h2>
    <h3>Comprehensive Expertise:</h3>
    <p>
    ●	With a diverse range of legal services, we offer comprehensive solutions to address your specific needs.
    </p>
  </section>
  <section>
    <h3>Client-Centric Approach:</h3>
    <p>
    ●	Our commitment to client satisfaction is reflected in our personalised and attentive approach to every case.
    </p>
  </section>
  <section>
    <h3>Integrity and Excellence:</h3>
    <p>
    ●	Upholding the highest standards of integrity and legal excellence, we strive for positive outcomes in every matter we handle.
    </p>
  </section>
  <section>
    <h3>Experienced Team:</h3>
    <p>
    ●	Backed by a team of experienced and skilled legal professionals, Criya Pragya ensures top-notch representation and guidance.
    </p>
  </section>
  <section>
    <p>Explore the legal possibilities with Criya Pragya - Your Legal Partners at Criya Hub. Contact us today for a consultation and let us navigate the legal complexities for you.</p>
  </section>
  
</main>

</>
<Footer />
  </div>
  )
}

export default Second_page