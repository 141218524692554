import React from "react";
import "./footer.css";
import { BsInstagram, BsFacebook } from "react-icons/bs";
import { Link, NavLink } from "react-router-dom";

const Footer = () => {
  return (
    <div className="criya__footer">
      <div className="criya__footer-links">
        <div className="criya__footer-links_logo_about_contact_container">
          <h1 className="criya__footer-links_logo">criya</h1>
          <p className="criya__footer-links_logo_about_text">
          simply dummy text of the printing and typesetting industry.
          </p>
          <div className="criya__footer-links_contact_container">
            <div className="criya__footer-links_contact_container_phone_container">
              <h4 className="criya__footer-links_contact_container_phone">
                Contact
              </h4>
              <p className="criya__footer-links_contact_container_phone_number">
                Phone No: 9876543210
              </p>
            </div>
            <div className="criya__footer-links_contact_container_email_container">
              <h4 className="criya__footer-links_contact_container_email">
                Email ID
              </h4>
              <p className="criya__footer-links_contact_container_emailId">
                <a href="info@criya.com">info@criya.com</a>
              </p>
            </div>
          </div>
          <div className="criya__footer-links_social_container">
            <h4>FOLLOW US ON</h4>
            <div className="criya__footer-links_contact_container_social_icons">
              <BsInstagram className="criya__footer-links_contact_container_social_icon" />
              <BsFacebook className="criya__footer-links_contact_container_social_icon" />
            </div>
          </div>
        </div>
        <div className="criya__footer-course_container">
          <h3>Links</h3>
          <li className="criya__footer_li">
            <Link to="/home">Home</Link>
          </li>
          <li className="criya__footer_li">
            <Link to="/ourstory">Our Story</Link>
          </li>
          <li className="criya__footer_li">
            <Link to="/ourservices">Our Services</Link>
          </li>
          <li className="criya__footer_li">
            <Link to="/contact">Contact Us</Link>
          </li>
          <li className="criya__footer_li">
            <Link to="/terms">Terms and conditions</Link>
          </li>
        </div>
        <div className="criya__footer-newsletter_container">
          <h3>JOIN A NEWSLETTER</h3>
          <li className="criya__footer_li">Your Email Id</li>
          <input
            className="criya__footer-newsletter_input"
            type="email"
            placeholder="Enter your email id"
          />
        </div>
        <h3 className="criya__footer-careers">CAREERS</h3>
      </div>
    </div>
  );
};

export default Footer;